import { RefObject, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { setIframe } from '@redux/slices/storeSlice';
import ResizeObserver from 'resize-observer-polyfill';

export const useIframe = (storeWrapper: RefObject<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const { iframe } = useAppSelector((state) => {
        return { iframe: state.store.iframe };
    });

    let windowSize: { width: number; height: number } = {
        width: 0,
        height: 0,
    };

    useEffect(() => {
        if (!iframe || !storeWrapper.current) return;
        // Listen to document height changes
        const resizeObserver = new ResizeObserver((entries) => {
            windowSize = {
                width: entries[0].target.clientWidth,
                height: entries[0].target.clientHeight,
            };
            parent.postMessage({ windowSize }, '*');
        });
        // document.body.style.overflow = 'hidden';
        resizeObserver.observe(storeWrapper.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, [iframe, storeWrapper]);

    useEffect(() => {
        if (!storeWrapper.current) return;

        // Detect iframe
        let isIframe = false;
        try {
            isIframe = window.self !== window.top;
        } catch (e) {
            isIframe = false;
        }
        dispatch(setIframe(isIframe));
    }, [storeWrapper]);

    return windowSize;
};
