//import { useCallback, useEffect, useState } from 'react';
//import ReactGA from 'react-ga4';
import { EventSquareEdition, GoogleTagIntegrationCredentials } from '@type/EditionTypes';
import { EventSquareEvent } from '@type/EventTypes';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import TagManager from '@sooro-io/react-gtm-module';

export const useGoogleTag = (event: EventSquareEvent | undefined, edition: EventSquareEdition | undefined) => {
    const location = useLocation();
    const initializedRef = useRef<boolean>(false);

    // Listen for Track events
    useEffect(() => {
        document.addEventListener('Track', handleTrack);
        return () => {
            document.removeEventListener('Track', handleTrack);
        };
    }, [event, edition]);

    // Initialize Google Tag manager
    useEffect(() => {
        const googleTagIntegration = edition?.integrations.find(
            (intergration) => intergration.type === 'google_tag_manager',
        );
        if (initializedRef?.current || !edition || !googleTagIntegration) {
            return;
        }
        const { google_tag_manager_id } = googleTagIntegration.credentials as GoogleTagIntegrationCredentials;
        TagManager.initialize({
            gtmId: google_tag_manager_id,
        });
        initializedRef.current = true;
    }, [event, edition]);

    useEffect(() => {
        if (!initializedRef?.current || !event || !edition) {
            return;
        }
    }, [event, edition, location]);

    const handleTrack = useCallback(
        (data: any) => {
            if (!initializedRef?.current) {
                return;
            }
            switch (data.detail.type) {
                case 'AddToCart':
                    // Tracking is disabled - tag will receive data from the dataLayer
                    break;
                default:
                    break;
            }
        },
        [event, edition],
    );
};
