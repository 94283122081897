import { useTranslation } from 'react-i18next';
import { BackLink } from '@components/BackLink';

interface ChangeShowLinkProps {
    to: string;
    toCalendar?: boolean;
}

export const ChangeShowLink: React.FC<ChangeShowLinkProps> = (props) => {
    const { to, toCalendar } = props;
    const { t } = useTranslation();
    return (
        <BackLink to={to} linkText={toCalendar ? t('change_show_link.to_calendar') : t('change_show_link.to_shop')} />
    );
};
