import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Show } from '@type/EditionTypes';

import { useAppSelector } from '@redux/hooks';

import { areDatesLessThanDayApart, formatDate, formatTimeSimple, getQuantityInShow } from '@lib/helpers';

import { AppLink } from '@components/Link';
import { Icon } from '@components/Icon';

interface ShowsListProps {
    shows: Show[];
    lang: string | undefined;
    showModeReservation?: boolean;
}

export const ShowsList: React.FC<ShowsListProps> = (props) => {
    const { shows, lang, showModeReservation } = props;
    const { cart } = useAppSelector((state) => {
        return { cart: state.store.cart };
    });

    return (
        <StyledShowsList>
            {shows.length > 0 &&
                shows.map((show) => {
                    return (
                        <ShowItem
                            show={show}
                            lang={lang}
                            key={show.id}
                            quantity={getQuantityInShow(show.id, cart)}
                            showModeReservation={showModeReservation}
                        />
                    );
                })}
        </StyledShowsList>
    );
};

const StyledShowsList = styled.ul`
    margin-bottom: 1.5rem;
`;

interface ShowItemProps {
    show: Show;
    lang: string | undefined;
    quantity: number;
    showModeReservation?: boolean;
}

const ShowItem: React.FC<ShowItemProps> = (props) => {
    const { show, lang, quantity, showModeReservation } = props;
    const { t } = useTranslation();
    const { search } = useLocation();

    const showName = show.name
        ? show.name
        : show.date.start
        ? formatDate(show.date.start, show.date.hide_hours, lang)
        : show.id;

    const getMessage = () => {
        switch (show.status) {
            case 'low':
                return t('shows_list.status_low');
                break;
            case 'soldout':
                return t('shows_list.status_soldout');
                break;
            default:
                return null;
        }
    };

    const message = getMessage();

    const renderShowSubtitle = () => {
        if (!show.name || !show.date.start) return null;
        let subtitleText = null;
        if (show.date.start && show.date.end && !areDatesLessThanDayApart(show.date.start, show.date.end)) {
            subtitleText = `${formatDate(show.date.start, show.date.hide_hours, lang)} - ${formatDate(
                show.date.end,
                show.date.hide_hours,
                lang,
            )}`;
        } else if (show.date.start) {
            subtitleText = formatDate(show.date.start, show.date.hide_hours, lang);
        }
        return <StyledShowDate>{subtitleText}</StyledShowDate>;
    };

    return (
        <ShowListItem>
            <AppLink to={`/shows/${show.id}${search}`}>
                <ShowListItemBox>
                    <StyledShowDetails>
                        {!showModeReservation && (
                            <>
                                <StyledShowName>{showName}</StyledShowName>
                                {renderShowSubtitle()}
                            </>
                        )}
                        {showModeReservation && (
                            <>
                                <StyledShowName>{formatTimeSimple(show.date.start, lang)}</StyledShowName>
                                {show.name && <StyledShowDate>{show.name}</StyledShowDate>}
                            </>
                        )}
                        {message && <StyledShowMessage>{message}</StyledShowMessage>}
                    </StyledShowDetails>
                    {!!quantity && <StyledQuantity>{quantity}</StyledQuantity>}
                    <StyledIcon>
                        <Icon name="arrow-right-s-line" />
                    </StyledIcon>
                </ShowListItemBox>
            </AppLink>
        </ShowListItem>
    );
};

const ShowListItem = styled.li`
    padding: 0.5rem 0;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--theme-lines);
    }

    a {
        padding: 0.5rem 0;
        text-decoration: none;
        color: inherit;
        transition: all 200ms ease;
        display: block;

        &:hover {
            padding-left: 0.75rem;
            color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};

            .icon {
                color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
            }
        }
    }
`;

const ShowListItemBox = styled.div`
    display: flex;
    align-items: center;
`;

const StyledShowDetails = styled.div`
    margin-right: auto;
`;

const StyledShowName = styled.h3`
    font-size: 1.125rem;
    font-weight: 800;
    color: var(--theme-font-color-heading);
`;

const StyledShowDate = styled.h5`
    font-size: 0.875rem;
    font-weight: 400;
    font-variation-settings: 'ital' 125;
    margin-top: 0.25rem;
    color: var(--theme-font-color-subtle);
`;

const StyledQuantity = styled.div`
    margin-left: 0.5rem;
    background-color: ${(props) => props.theme.colors.primary};
    color: #fff;
    font-weight: 600;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
`;

const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.primary};
    margin-left: 0.5rem;
    margin-right: 0.625rem;
`;

const StyledShowMessage = styled.p`
    text-transform: uppercase;
    color: var(--theme-font-color-subtle);
    font-weight: 700;
    font-size: 0.75rem;
    margin-top: 0.375rem;
`;
