import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { AppLink } from '@components/Link';
import { Icon } from './Icon';
import { useAppSelector } from '@redux/hooks';
import { LangSwitch } from './LangSwitch';

interface MobileNavProps {
    languages: string[];
    hasInfo: boolean;
    testmode: boolean;
}

export const MobileNav: React.FC<MobileNavProps> = (props) => {
    const { languages, hasInfo, testmode } = props;
    const { t } = useTranslation();

    const { iframe } = useAppSelector((state) => {
        return {
            iframe: state.store.iframe,
        };
    });

    if (iframe) return null;

    return (
        <StyledMobileNav preview={!!testmode}>
            <StyledIcon>
                <AppLink to="/">
                    <Icon name="esq" alt="EventSquare" />
                </AppLink>
            </StyledIcon>
            <StyledNavList>
                {hasInfo && (
                    <StyledNavItem>
                        <AppLink to="/info" className={({ isActive }) => (isActive ? 'active' : 'none')}>
                            {t('mobile_nav.info')}
                        </AppLink>
                    </StyledNavItem>
                )}
                <StyledNavItem>
                    <AppLink to="/support" className={({ isActive }) => (isActive ? 'active' : 'none')}>
                        {t('mobile_nav.support')}
                    </AppLink>
                </StyledNavItem>
                <LangSwitch languages={languages} />
            </StyledNavList>
        </StyledMobileNav>
    );
};

const StyledMobileNav = styled.nav<{ preview: boolean }>`
    display: none;

    @media (max-width: 57em) {
        background-color: var(--theme-canvas);
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        border-bottom: 1px solid var(--theme-lines);

        ${(props) =>
            props.preview &&
            css`
                margin-top: 3rem;
            `}
    }
`;

const StyledIcon = styled.div`
    color: var(--theme-gray-500);
    margin-right: auto;
    font-size: 2.25rem;
    display: flex;
    justify-content: start;
    align-items: center;

    a {
        text-decoration: none;
        color: inherit;
        display: flex;
        justify-content: start;
        align-items: center;
    }
`;

const StyledNavList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.75rem;
`;

const StyledNavItem = styled.li`
    font-size: var(--theme-font-size-min-1);

    a {
        text-decoration: none;
        transition: color 200ms ease;
        color: var(--theme-font-color-subtle);
        font-weight: 500;

        &.active,
        &:hover {
            font-weight: 700;
            color: var(--theme-font-color);
        }
    }
`;
