import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface InputProps {
    type?: string;
    name: string;
    value: string | number;
    onChange: (e: any) => void;
    className?: string;
    autocomplete?: string;
    autoFocus?: boolean;
    min?: number;
    max?: number;
    id?: string;
    placeholder?: string;
}

export const Input: React.FC<InputProps> = (props) => {
    const { type, autocomplete, name, value, onChange, className, autoFocus, id, placeholder, ...rest } = props;
    return (
        <StyledInput
            type={type ? type : 'text'}
            name={name}
            value={value}
            onChange={onChange}
            className={className}
            autoFocus={autoFocus}
            autoComplete={autocomplete}
            id={id}
            placeholder={placeholder}
            {...rest}
        />
    );
};

const StyledInput = styled.input`
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 1.125rem;
    font-weight: 400;
    border: 1px solid ${(props) => props.theme.colors.lines};
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;
    color: ${(props) => props.theme.colors.fontDark};
    background-color: #fff;
    outline: none;
    width: 100%;
    caret-color: ${(props) => props.theme.colors.fontLight};
    transition: all 200ms ease;

    &::placeholder {
        color: ${(props) => props.theme.colors.fontLight};
    }

    &:focus {
        border: 1px solid ${(props) => props.theme.colors.primary};
        box-shadow: ${(props) => props.theme.helpers.boxShadow(props.theme.colors.primary)};
        outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

interface TextAreaProps {
    name: string;
    value: string | number;
    onChange: (e: any) => void;
    className?: string;
    autoFocus?: boolean;
    min?: number;
    max?: number;
    id?: string;
}

export const TextArea: React.FC<TextAreaProps> = (props) => {
    const { name, value, onChange, className, autoFocus, id, ...rest } = props;
    return (
        <StyledTextArea
            name={name}
            value={value}
            onChange={onChange}
            className={className}
            autoFocus={autoFocus}
            id={id}
            {...rest}
        />
    );
};

const StyledTextArea = styled.textarea`
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 1.125rem;
    font-weight: 400;
    border: 1px solid ${(props) => props.theme.colors.lines};
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;
    color: ${(props) => props.theme.colors.fontDark};
    background-color: #fff;
    outline: none;
    width: 100%;
    caret-color: ${(props) => props.theme.colors.fontLight};
    transition: all 200ms ease;
    resize: vertical;
    overflow: auto;

    &:focus {
        border: 1px solid ${(props) => props.theme.colors.primary};
        box-shadow: ${(props) => props.theme.helpers.boxShadow(props.theme.colors.primary)};
        outline: none;
    }
`;

interface LabelProps {
    children: React.ReactNode;
    htmlFor: string;
    optional?: boolean;
}

export const Label: React.FC<LabelProps> = (props) => {
    const { children, htmlFor, optional } = props;
    const { t } = useTranslation();
    return (
        <StyledLabel htmlFor={htmlFor}>
            {children}
            {optional && <span className="optional">{`(${t('form_elements.label_optional')})`}</span>}
        </StyledLabel>
    );
};

const StyledLabel = styled.label`
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    span.optional {
        font-size: 0.75rem;
        display: inline-block;
        margin-left: 0.25rem;
    }
`;

interface FormDivProps {
    children: React.ReactNode;
}

export const FormDiv: React.FC<FormDivProps> = (props) => {
    const { children } = props;
    return <StyledFormDiv>{children}</StyledFormDiv>;
};

const StyledFormDiv = styled.div`
    margin-bottom: 1.5rem;

    &:last-of-type {
        margin-bottom: 0;
    }
`;
