import symbols from '../symbols/symbol-defs.svg';

export interface IconProps {
    name: string;
    alt?: string;
}

export const Icon: React.FC<IconProps> = (props) => {
    const { name, alt } = props;
    return (
        <svg className={`icon icon-${name}`} role="img">
            {!!alt && <title>{alt}</title>}
            <use xlinkHref={`${symbols}?v=${import.meta.env.VITE_APP_VERSION}#icon-${name}`} />
        </svg>
    );
};
