import { useCallback, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4-forked';
import { EventSquareEdition, GoogleIntegrationCredentials } from '@type/EditionTypes';
import { EventSquareEvent } from '@type/EventTypes';

export const useGA = (event: EventSquareEvent | undefined, edition: EventSquareEdition | undefined) => {
    const initializedRef = useRef<boolean>(false);

    useEffect(() => {
        document.addEventListener('Track', handleTrack);
        return () => {
            document.removeEventListener('Track', handleTrack);
        };
    }, [event, edition]);

    useEffect(() => {
        const gaIntegration = edition?.integrations.find((intergration) => intergration.type === 'google_analytics');
        if (initializedRef?.current || !edition) {
            return;
        }

        // EventSquare GA4 Property ID
        const gaConfigs = [
            {
                trackingId: 'G-MCSQTFV9JV',
            },
        ];

        // Organisation GA4 Property ID
        if (gaIntegration) {
            const { google_tracking_id } = gaIntegration.credentials as GoogleIntegrationCredentials;
            gaConfigs.push({
                trackingId: google_tracking_id,
            });
        }

        ReactGA.initialize(gaConfigs);
        initializedRef.current = true;
    }, [event, edition]);

    const handleTrack = useCallback(
        (data: any) => {
            if (!initializedRef?.current) {
                return;
            }
            switch (data.detail.type) {
                case 'AddToCart':
                    const category = event?.name + (edition?.name ? ` - ${edition?.name}` : '');
                    ReactGA.event('add_to_cart', {
                        items: [
                            {
                                item_id: data.detail.type_id,
                                item_name: data.detail.name,
                                item_category: category,
                            },
                        ],
                        currency: event?.currency,
                        value: data.detail.price,
                    });
                    break;
                default:
                    break;
            }
        },
        [event, edition],
    );
};
