import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Show } from '@type/EditionTypes';
import { Icon } from '@components/Icon';
import { ShowsCalendarList } from '@components/ShowsCalendarList';

interface ShowsCalendarProps {
    shows: Show[];
}

export const ShowsCalendar: React.FC<ShowsCalendarProps> = (props) => {
    const { shows } = props;
    const { lang, editionUri, channelUri } = useParams();
    const navigate = useNavigate();

    if (!lang || !editionUri || !channelUri) return null;

    const replace = (str: string | null) => {
        if (!str) return '';
        return str.replace(/-/g, '/');
    };

    const midnightDate = (str: string) => {
        return new Date(new Date(replace(str)).setHours(0, 0, 0, 0));
    };

    const now = new Date(new Date().setHours(0, 0, 0, 0));
    const showsWithDate = shows
        .filter((show) => !!show.date.start)
        //.filter((show) => new Date(show.date.start as string) > now)
        .sort((showA, showB) => {
            return new Date(replace(showA.date.start)) < new Date(replace(showB.date.start)) ? -1 : 1;
        });

    const minDate = new Date(replace(showsWithDate[0].date.start));
    const maxDate = new Date(replace(showsWithDate[showsWithDate.length - 1].date.start));

    let startDate = now;
    //startDate = new Date(startDate.setHours(0, 0, 0, 0));
    const yesterdayBeforeMidnight = new Date(new Date(now.getTime() - 24 * 60 * 60 * 1000).setHours(23, 59, 59));
    // Filter if showWithDate got dates in future
    const futureShowsWithDate = showsWithDate.filter(
        (show) => new Date(replace(show.date.start)) > yesterdayBeforeMidnight,
    );
    if (futureShowsWithDate.length > 0) {
        // If there are shows after midnight today set startDate to first coming date (including a date today)
        startDate = midnightDate(futureShowsWithDate[0].date.start as string);
    } else {
        // Else set startDate to latest show
        startDate = new Date(replace(showsWithDate[showsWithDate.length - 1].date.start) as string);
    }

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedShows, setSelectedShows] = useState<Show[]>([]);

    useEffect(() => {
        if (!selectedDate) return;

        const showsArray = shows.filter((show) => {
            if (!show.date.start) return false;
            return midnightDate(show.date.start).getTime() === selectedDate.getTime();
        });

        if (showsArray.length === 1) {
            navigate(`/${lang}/${editionUri}/${channelUri}/shows/${showsArray[0].id}`);
        }
        setSelectedShows(showsArray);
    }, [shows, selectedDate]);

    const isDateDisabled = ({ date }: { date: Date }) => {
        const findDate = showsWithDate.find((show) => {
            const showStart = new Date(new Date(replace(show.date.start)).setHours(0, 0, 0, 0));
            return date.getTime() === showStart.getTime();
        });
        return !findDate;
    };

    return (
        <>
            <StyledCalendar>
                <Calendar
                    defaultActiveStartDate={startDate}
                    onClickDay={(value) => {
                        setSelectedDate(value);
                    }}
                    locale={lang}
                    minDate={minDate}
                    maxDate={maxDate}
                    value={selectedDate}
                    view="month"
                    tileDisabled={isDateDisabled}
                    nextLabel={<Icon name="arrow-right-s-line" />}
                    next2Label={null}
                    prevLabel={<Icon name="arrow-left-s-line" />}
                    prev2Label={null}
                />
            </StyledCalendar>
            {selectedShows.length > 0 && <ShowsCalendarList shows={selectedShows} lang={lang} />}
        </>
    );
};

const StyledCalendar = styled.div`
    //border: 1px solid red;

    .react-calendar {
        width: 100%;
        max-width: 27.5rem;
        margin: 0 auto 1.5rem;
        color: ${(props) => props.theme.colors.fontDark};
        font-family: ${(props) => props.theme.fontFamily};
        border: none;

        .react-calendar__navigation {
            background-color: #fff;
            border-bottom: 1px solid ${(props) => props.theme.colors.lines};
            padding: 0.5rem 0;
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;

            .react-calendar__navigation__arrow {
                color: ${(props) => props.theme.colors.primary};
                font-size: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                transition: color 300ms ease;

                &:hover {
                    background-color: #fff;
                    color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
                }

                &:disabled {
                    background-color: #fff;
                    color: ${(props) => props.theme.colors.lines};
                }
            }

            .react-calendar__navigation__label {
                font-family: ${(props) => props.theme.fontFamily};
                font-size: 1rem;
                font-weight: 700;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
                text-transform: capitalize;
                color: ${(props) => props.theme.colors.fontDark};
            }
        }

        .react-calendar__month-view__weekdays {
            font-weight: 600;
            font-size: 0.875rem;
            color: ${(props) => props.theme.colors.fontLight};

            abbr[title] {
                text-decoration: none;
            }
        }

        button.react-calendar__tile {
            font-size: 1rem;
            font-weight: 700;
            font-family: ${(props) => props.theme.fontFamily};
            color: ${(props) => props.theme.colors.primary};
            position: relative;
            padding: 0;
            padding-top: calc(100% / 7 * 0.7);
            overflow: hidden;
            //border: 1px solid green;

            @media (max-width: 57em) {
                padding-top: calc(100% / 7 * 1);
            }

            > abbr {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: calc(100% - 1.5rem);
                height: calc(100% - 1rem);
                top: 0.5rem;
                bottom: 0.5rem;
                left: 0.75rem;
                right: 0.75rem;
                /* padding: 0.5rem; */
                border-radius: 0.25rem;
                transition: all 300ms ease;

                @media (max-width: 57em) {
                    width: calc(100% - 0.5rem);
                    height: calc(100% - 0.5rem);
                    top: 0.25rem;
                    bottom: 0.25rem;
                    left: 0.25rem;
                    right: 0.25rem;
                }
            }

            &:hover {
                background-color: #fff;

                > abbr {
                    background-color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -2)};
                    color: #fff;
                }
            }

            &.react-calendar__tile--active {
                background-color: #fff;

                > abbr {
                    background-color: ${(props) => props.theme.colors.primary};
                    color: #fff;
                }
            }

            &.react-calendar__tile--now {
                background-color: #fff;

                > abbr {
                    border: 1px solid ${(props) => props.theme.colors.primary};
                }

                &:hover > abbr {
                    background-color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -2)};
                }
            }

            &.react-calendar__month-view__days__day--neighboringMonth {
                > abbr {
                    color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -3)};
                }

                &:hover > abbr {
                    color: #fff;
                    background-color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -2)};
                }

                &:disabled {
                    > abbr {
                        color: ${(props) => props.theme.colors.lines};
                        background-color: #fff;
                    }

                    &:hover > abbr {
                        background-color: inherit;
                        color: inherit;
                    }
                }
            }

            &:disabled {
                color: ${(props) => props.theme.colors.lines};
                background-color: #fff;

                &:hover > abbr {
                    background-color: inherit;
                    color: inherit;
                }
            }
        }
    }
`;
