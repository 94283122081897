import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Show } from '@type/EditionTypes';

import { formatTimeSimple } from '@lib/helpers';

import { AppLink } from '@components/Link';

interface ShowsCalendarListProps {
    shows: Show[];
    lang: string | undefined;
}

export const ShowsCalendarList: React.FC<ShowsCalendarListProps> = (props) => {
    const { shows, lang } = props;

    return (
        <StyledShowsCalendarList>
            {shows.length > 0 &&
                shows.map((show) => {
                    return <ShowItem show={show} lang={lang} key={show.id} />;
                })}
        </StyledShowsCalendarList>
    );
};

const StyledShowsCalendarList = styled.ul`
    width: 100%;
    max-width: 27.5rem;
    margin: 0 auto 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
`;

interface ShowItemProps {
    show: Show;
    lang: string | undefined;
}

const ShowItem: React.FC<ShowItemProps> = (props) => {
    const { show, lang } = props;
    const { t } = useTranslation();
    const { search } = useLocation();

    return (
        <StyledAppLink to={`/shows/${show.id}${search}`}>
            <StyledShowListItem soldout={show.status === 'soldout'}>
                <div className="show-hours">{formatTimeSimple(show.date.start, lang)}</div>
                <div className="show-status">{t(`shows_calendar_list.status_${show.status}`)}</div>
            </StyledShowListItem>
        </StyledAppLink>
    );
};

const StyledAppLink = styled(AppLink)`
    text-decoration: none;
    text-align: center;
    border-radius: 0.25rem;
`;

const StyledShowListItem = styled.li<{ soldout: boolean }>`
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-radius: 0.25rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    transition: all 300ms ease;

    &:hover {
        background-color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -4)};
    }

    .show-hours {
        font-size: 1rem;
    }

    .show-status {
        font-size: 0.75rem;
        text-transform: uppercase;
        margin-top: 0.125rem;
    }

    ${(props) =>
        props.soldout &&
        css`
            color: ${(props) => props.theme.colors.fontLight};
            border-color: ${(props) => props.theme.colors.fontLight};
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }

            .show-hours {
                color: ${(props) => props.theme.colors.fontMedium};
            }
        `}
`;
