import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { AppLink } from '@components/Link';
import { LangSwitch } from './LangSwitch';

interface ChannelNavProps {
    languages: string[];
    hasInfo: boolean;
}

export const ChannelNav: React.FC<ChannelNavProps> = (props) => {
    const { languages, hasInfo } = props;
    const { t } = useTranslation();

    return (
        <StyledChannelNav>
            <StyledNavList>
                {hasInfo && (
                    <StyledNavItem>
                        <AppLink to="/info" className={({ isActive }) => (isActive ? 'active' : 'none')}>
                            {t('channel_nav.info')}
                        </AppLink>
                    </StyledNavItem>
                )}
                <StyledNavItem>
                    <AppLink to="/support" className={({ isActive }) => (isActive ? 'active' : 'none')}>
                        {t('channel_nav.support')}
                    </AppLink>
                </StyledNavItem>
            </StyledNavList>
            <LangSwitch languages={languages} />
        </StyledChannelNav>
    );
};

const StyledChannelNav = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--theme-lines);
    padding: 0 2.5rem;

    @media (max-width: 57em) {
        display: none;
    }
`;

const StyledNavList = styled.ul`
    display: flex;
    gap: 0.75rem;
    margin-right: auto;
`;

const StyledNavItem = styled.li`
    font-size: var(--theme-font-size-min-1);
    padding: 0.75rem 0;

    a {
        text-decoration: none;
        color: var(--theme-font-color-subtle);
        font-weight: 500;
        transition: color 200ms ease;

        &.active,
        &:hover {
            font-weight: 700;
            color: var(--theme-font-color);
        }
    }
`;
