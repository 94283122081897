import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '@redux/hooks';
import { fetchCart } from '@redux/slices/storeSlice';

import { TicketType, EditTicketType, Show } from '@type/EditionTypes';
import { CartPaymentOption, CartTicketType, CartTicketTypeShow, PlaceType } from '@type/CartTypes';
import { Date } from '@type/EventTypes';

import { formatCurrency, formatDate, getTypeMode } from '@lib/helpers';

import { EditTypeModal } from '@components/modals/EditTypeModal';
import { Button } from '@components/Buttons';
import { EmptyCart } from '@components/EmptyStates';
import { AddCouponLink } from '@components/modals/CouponModal';
import { Deals } from '@components/Deals';
import { AppLink } from '@components/Link';
import { devLog } from '@lib/dev';
import { api } from '@lib/api';

interface ShowPlacesRowsType {
    id: string;
    places: PlaceType[];
    rows: string[];
}

export const Cart = () => {
    const { event, cart, edition, previewToken, iframe } = useAppSelector((state) => {
        return {
            event: state.store.event,
            cart: state.store.cart,
            edition: state.store.edition,
            previewToken: state.store.previewToken,
            iframe: state.store.iframe,
        };
    });

    const dispatch = useAppDispatch();
    const { lang, editionUri, channelUri } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [activeType, setActiveType] = useState<EditTicketType | undefined>(undefined);
    const [activeShowDate, setActiveShowDate] = useState<Date | undefined>(undefined);
    const [activeShowName, setActiveShowName] = useState<string | undefined>(undefined);
    const [fastLaneUrl, setFastLaneUrl] = useState<string | undefined>(undefined);

    const [showsWithTypesOnMultipleRows, setShowsWithTypesOnMultipleRows] = useState<ShowPlacesRowsType[]>([]);

    if (!event || !cart || !edition) {
        return null;
    }

    useEffect(() => {
        const fastLane = cart.fastlane_url;
        const channelUrl = edition.channel.url;
        const storeUrl = previewToken ? `${channelUrl}&preview_token=${previewToken}` : channelUrl;
        setFastLaneUrl(`${fastLane}?s=${encodeURIComponent(storeUrl)}`);
    }, [cart, edition.channel.url, previewToken]);

    useEffect(() => {
        const showIdsInCart = [
            ...new Set(
                cart.items.types
                    .filter((type: CartTicketType) => type.show?.id)
                    .map((type: CartTicketType) => type.show?.id),
            ),
        ];

        const showPlacesRowsArr = showIdsInCart
            .map((showId: string | undefined) => {
                if (!showId) {
                    return null;
                }

                const allTypesFromShow = cart.items.types.filter((type: CartTicketType) => type.show?.id === showId);

                let placesArray: PlaceType[] = [];

                allTypesFromShow.forEach((type: CartTicketType) => {
                    if (type.seatmap) {
                        placesArray = [...placesArray, ...type.seatmap.places];
                    }
                });

                const rows = [...new Set(placesArray.map((place: PlaceType) => place.data.row))];

                return {
                    id: showId,
                    places: placesArray,
                    rows,
                };
            })
            .filter((show: any) => !!show)
            .filter((show: any) => show.rows.length > 1);

        setShowsWithTypesOnMultipleRows(showPlacesRowsArr as ShowPlacesRowsType[]);
    }, [cart]);

    const editType = async (type: CartTicketType) => {
        if (type.seatmap) {
            navigate(`/${lang}/${editionUri}/${channelUri}/shows/${type.show?.id}`);
        }

        if (!type.show) {
            const tempType = edition.channel.types.find((channelType: TicketType) => channelType.id === type.id);
            setActiveType({ ...tempType, showId: undefined } as EditTicketType);
            return;
        }

        try {
            const subdomain = event.uri;
            const params = {
                cart: cart.cartid,
                language: lang,
            };
            const response = await api.get(`/store/${subdomain}/${editionUri}/${channelUri}/${type.show.id}`, {
                params,
            });
            const show = response.data.show;
            const typeFromShow = response.data.show.types.find((showType: TicketType) => showType.id === type.id);

            if (show && !typeFromShow) {
                navigate(`/${lang}/${editionUri}/${channelUri}/shows/${type.show?.id}`);
                return;
            }
            setActiveShowDate(show.date);
            setActiveShowName(show.name);
            setActiveType({ ...typeFromShow, showId: show.id });
        } catch (error: unknown) {
            devLog(error);
        }
    };

    const removeType = async (type: CartTicketType) => {
        try {
            const data: { show?: string; voucher?: string } = {};
            if (type.show) {
                data['show'] = type.show.id;
            }
            if (type.voucher) {
                data['voucher'] = type.voucher.code;
            }
            await api.delete(`/cart/${cart.cartid}/types/${type.id}`, { data });

            dispatch(fetchCart(lang));
        } catch (error) {
            devLog(error);
        }
    };

    const closeModal = () => {
        setActiveType(undefined);
        setActiveShowDate(undefined);
        setActiveShowName(undefined);
    };

    const getShowTitle = (show: CartTicketTypeShow | Show | undefined) => {
        if (!show) return '';

        if (show.name) {
            return show.date.start
                ? show.name + ' - ' + formatDate(show.date.start, show.date.hide_hours, lang)
                : show.name;
        }
        return show.date.start ? formatDate(show.date.start, show.date.hide_hours, lang) : show.id;
    };

    const getShow = (showId: string): Show | undefined => {
        return edition.channel.shows.find((show: CartTicketTypeShow) => show.id === showId);
    };

    return (
        <>
            {cart.items.types.length === 0 && (
                <div>
                    <EmptyCart cartId={cart.cartid} brandingColor={edition.branding.color} />
                    {cart.deals.length > 0 && <Deals deals={cart.deals} cartId={cart.cartid} />}
                </div>
            )}
            {cart.items.types.length > 0 && (
                <>
                    <AddCouponLink cartId={cart.cartid} brandingColor={edition.branding.color} />
                    <StyledCartTitle>{t('cart.title')}</StyledCartTitle>
                    {showsWithTypesOnMultipleRows.length > 0 && (
                        <StyledWarning>
                            <p>{t('cart.multiple_rows')}</p>
                            <ul>
                                {showsWithTypesOnMultipleRows.map((show: ShowPlacesRowsType) => {
                                    return <li key={show.id}>{getShowTitle(getShow(show.id))}</li>;
                                })}
                            </ul>
                        </StyledWarning>
                    )}
                    <StyledCartList>
                        {cart.items.types.map((type: CartTicketType, index: number) => {
                            return (
                                <StyledCartListItem key={index + '-' + type.id}>
                                    <StyledQuantity>{`${type.quantity} ×`}</StyledQuantity>
                                    <div>
                                        <StyledTypeName>{type.name}</StyledTypeName>
                                        {type.show && (
                                            <StyledTypeShowName>
                                                <AppLink to={`/shows/${type.show.id}`}>
                                                    {/* {type.show.name
                                                        ? type.show.name
                                                        : type.show.date.start
                                                        ? formatDate(
                                                              type.show.date.start,
                                                              type.show.date.hide_hours,
                                                              lang,
                                                          )
                                                        : type.show.id} */}
                                                    {getShowTitle(type.show)}
                                                </AppLink>
                                            </StyledTypeShowName>
                                        )}
                                        <StyledTypePrice>
                                            {type.price - type.discount > 0
                                                ? formatCurrency(type.price - type.discount, lang, event.currency)
                                                : t('cart.free')}
                                            {type.discount !== 0 && (
                                                <StyledTypeOriginalPrice>
                                                    {formatCurrency(type.price, lang, event.currency)}
                                                </StyledTypeOriginalPrice>
                                            )}
                                            {!!type.fee && (
                                                <StyledTypeFee>
                                                    {t('cart.fee', {
                                                        fee: formatCurrency(type.fee, lang, event.currency),
                                                    })}
                                                </StyledTypeFee>
                                            )}
                                        </StyledTypePrice>

                                        {type.seatmap &&
                                            type.seatmap.places.map((place, index) => {
                                                return (
                                                    <StyledTypePlace
                                                        key={index}
                                                    >{`${place.data.section} - ${place.data.row} - ${place.data.seat}`}</StyledTypePlace>
                                                );
                                            })}

                                        <StyledTypeEdit>
                                            {!type.voucher && (
                                                <StyledTypeEditButton
                                                    onClick={() => {
                                                        editType(type);
                                                    }}
                                                >
                                                    {t('cart.edit')}
                                                </StyledTypeEditButton>
                                            )}
                                            {type.voucher && (
                                                <>
                                                    <StyledVoucherCode>{type.voucher.code}</StyledVoucherCode>
                                                    <StyledTypeEditButton onClick={() => removeType(type)}>
                                                        {t('cart.remove')}
                                                    </StyledTypeEditButton>
                                                </>
                                            )}
                                        </StyledTypeEdit>
                                    </div>
                                    <StyledTypeTotal>
                                        {formatCurrency(
                                            type.quantity * (type.price - type.discount + type.fee),
                                            lang,
                                            event.currency,
                                        )}
                                    </StyledTypeTotal>
                                </StyledCartListItem>
                            );
                        })}
                    </StyledCartList>
                    <StyledTotalList>
                        {cart.summary.vat && cart.summary.vat_total > 0 && (
                            <>
                                <StyledTotalSubItem>
                                    <StyledSubItemText>{t('cart.VAT_excl')}</StyledSubItemText>
                                    <StyledSubItemPrice>
                                        {formatCurrency(cart.summary.price, lang, event.currency)}
                                    </StyledSubItemPrice>
                                </StyledTotalSubItem>
                                {Object.keys(cart.summary.vat).map((vatKey) => {
                                    return (
                                        <StyledTotalSubItem key={vatKey}>
                                            <StyledSubItemText>{`${t('cart.VAT')} ${vatKey}%`}</StyledSubItemText>
                                            <StyledSubItemPrice>
                                                {formatCurrency(cart.summary.vat[vatKey], lang, event.currency)}
                                            </StyledSubItemPrice>
                                        </StyledTotalSubItem>
                                    );
                                })}
                            </>
                        )}
                        <StyledTotalItem>
                            <StyledTotalItemText>{t('cart.total')}</StyledTotalItemText>
                            <StyledTotalItemPrice>
                                {formatCurrency(cart.summary.total, lang, event.currency)}
                            </StyledTotalItemPrice>
                        </StyledTotalItem>
                    </StyledTotalList>
                    {cart.deals.length > 0 && <Deals deals={cart.deals} cartId={cart.cartid} />}
                    <StyledToFastLane>
                        <a href={fastLaneUrl} target={iframe ? '_parent' : '_self'} rel="noreferrer">
                            <Button fullWidth>{t('cart.to_checkout')}</Button>
                        </a>
                    </StyledToFastLane>
                    {cart.payment_options && (
                        <StyledPaymentOptions>
                            {cart.payment_options.map((payment_option: CartPaymentOption) => {
                                return payment_option.icons.map((icon: string, index: number) => {
                                    return (
                                        <img
                                            key={payment_option.identifier + '-' + index}
                                            src={icon}
                                            alt={payment_option.name}
                                        />
                                    );
                                });
                            })}
                        </StyledPaymentOptions>
                    )}
                </>
            )}
            <EditTypeModal
                type={activeType}
                cart={cart}
                closeModal={closeModal}
                showDate={activeShowDate}
                showName={activeShowName}
                isOnCartPage
                typeMode={getTypeMode(edition)}
                currency={event.currency}
                edition={edition}
            />
        </>
    );
};

const StyledWarning = styled.div`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    padding: 0.75rem;
    background-color: ${(props) => props.theme.colors.light};
    border-radius: 0.25rem;
    // text-align: center;

    ul {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        list-style: disc;
    }
`;

const StyledCartTitle = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    color: var(--theme-form-label);
    font-size: var(--theme-font-size-min-1);
    margin-bottom: 0.5rem;
`;

const StyledCartList = styled.ul``;

const StyledCartListItem = styled.li`
    display: grid;
    grid-template-columns: 4rem 1fr 6rem;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid var(--theme-lines);
`;

const StyledQuantity = styled.div`
    font-weight: 700;
    font-size: 1rem;
    color: var(--theme-font-color-heading);
`;

const StyledTypeName = styled.p`
    font-weight: 700;
    color: var(--theme-font-color-heading);
    font-size: 1rem;
`;

const StyledTypeShowName = styled.p`
    /* font-style: italic; */
    font-variation-settings: 'ital' 125;
    font-weight: 700;
    font-size: 0.875rem;
    color: ${(props) => props.theme.colors.fontMedium};
    margin-top: 0.25rem;

    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: ${(props) => props.theme.colors.primary};
        }
    }
`;

const StyledTypePrice = styled.p`
    font-weight: 400;
    font-size: 0.875rem;
    color: var(--theme-font-color);
    margin-top: 0.25rem;
`;

const StyledTypeOriginalPrice = styled.span`
    font-size: 0.6875rem;
    display: inline-block;
    margin-left: 0.25rem;
    color: var(--theme-font-color);
    text-decoration: line-through;
`;

const StyledTypeFee = styled.span`
    font-size: 0.6875rem;
    display: inline-block;
    margin-left: 0.25rem;
    color: var(--theme-font-color-subtle);
`;

const StyledTypePlace = styled.p`
    font-size: 0.875rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.fontMedium};
    margin-top: 0.25rem;
`;

const StyledTypeEdit = styled.div``;

const StyledTypeEditButton = styled.button`
    font-size: 0.875rem;
    font-weight: 700;
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) => props.theme.colors.primary};
    margin-top: 0.25rem;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    outline: none;
    border: none;
    background-color: transparent;
`;

const StyledVoucherCode = styled.span`
    display: inline-block;
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.fontDark};
    font-family: monospace;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin-right: 0.75rem;
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;
`;

const StyledTypeTotal = styled.div`
    text-align: right;
    font-weight: 600;
    font-size: 1rem;
`;

const StyledTotalList = styled.ul`
    padding: 1rem 0.5rem 1rem 4.5rem;
    margin-bottom: 2rem;
`;

const StyledTotalSubItem = styled.li`
    margin-bottom: 0.25rem;
    color: ${(props) => props.theme.colors.fontMedium};
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
`;

const StyledSubItemText = styled.div``;

const StyledSubItemPrice = styled.div`
    text-align: right;
`;

const StyledTotalItem = styled.li`
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    font-size: 1.125rem;
`;

const StyledTotalItemText = styled.div`
    font-weight: 700;
`;

const StyledTotalItemPrice = styled.div`
    font-weight: 700;
    text-align: right;
`;

const StyledToFastLane = styled.div`
    display: block;

    a {
        text-decoration: none;
    }

    @media (max-width: 57em) {
        display: none;
    }

    ${(props) =>
        props.theme.iframe &&
        css`
            display: none;
        `}
`;

const StyledPaymentOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    img {
        width: 1.875rem;
        height: auto;

        &:last-of-type {
            margin-right: 0;
        }
    }

    @media (min-width: 57em) {
        gap: 0.625rem;

        img {
            width: 2rem;
        }
    }
`;
