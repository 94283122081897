import { useCallback, useEffect, useRef } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { EventSquareEdition, TikTokIntegrationCredentials } from '@type/EditionTypes';
import { EventSquareEvent } from '@type/EventTypes';
import { useLocation } from 'react-router-dom';

export const useTikTokPixel = (event: EventSquareEvent | undefined, edition: EventSquareEdition | undefined) => {
    const location = useLocation();
    const initializedRef = useRef<boolean>(false);

    // Listen for Track events
    useEffect(() => {
        document.addEventListener('Track', handleTrack);
        return () => {
            document.removeEventListener('Track', handleTrack);
        };
    }, [event, edition]);

    // Initialize TikTok Pixel
    useEffect(() => {
        const tikTokIntegration = edition?.integrations.find((intergration) => intergration.type === 'tiktok_pixel');
        if (initializedRef?.current || !edition || !tikTokIntegration) {
            return;
        }
        const { pixel_id } = tikTokIntegration.credentials as TikTokIntegrationCredentials;
        TiktokPixel.init(pixel_id);
        initializedRef.current = true;
    }, [event, edition]);

    useEffect(() => {
        if (!initializedRef?.current || !event || !edition) {
            return;
        }
        TiktokPixel.pageView();
    }, [event, edition, location]);

    const handleTrack = useCallback(
        (data: any) => {
            if (!initializedRef?.current) {
                return;
            }
            switch (data.detail.type) {
                case 'AddToCart':
                    const category = event?.name + (edition?.name ? ` - ${edition?.name}` : '');
                    TiktokPixel.track('AddToCart', {
                        content_type: 'product',
                        content_id: data.detail.type_id,
                        content_category: category,
                        content_name: data.detail.name,
                        currency: event?.currency,
                        value: data.detail.price,
                    });
                    break;
                default:
                    break;
            }
        },
        [event, edition],
    );
};
