import { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';

import { defaultTheme, GlobalStyle } from '@styles/theme';

import { ThemeProvider as EsqThemeProvider } from '@eventsquare/uikit';

import { Event } from '@containers/Event';
import { Edition } from '@containers/Edition';
import { Channel } from '@containers/Channel';
import { Flow } from '@containers/Flow';
import { FlowShows } from '@containers/FlowShows';
import { FlowShowId } from '@containers/FlowShowId';
import { FlowExtras } from '@containers/FlowExtras';
import { Cart } from '@containers/Cart';
import { Info } from '@containers/Info';
import { Support } from '@containers/Support';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { setCartId, setPreviewToken, setReferrer } from '@redux/slices/storeSlice';
import { useIframe } from '@hooks/useIframe';

import '@eventsquare/uikit/css';
import { devLog } from '@lib/dev';

const App = () => {
    const dispatch = useAppDispatch();
    const { iframe, edition } = useAppSelector((state) => {
        return { iframe: state.store.iframe, edition: state.store.edition };
    });
    const cookies = new Cookies();

    const [primaryColor, setPrimaryColor] = useState(defaultTheme.colors.primary);

    useEffect(() => {
        if (document.referrer) {
            dispatch(setReferrer(document.referrer));
        }
        const searchParams = new URLSearchParams(window.location.search);

        // Remove cart cookie when cancel is requested in url
        if (searchParams.get('cancel') === 'true') {
            cookies.remove('cart', { path: '/' });
        }

        // Get cart from url
        const cartId = searchParams.get('cart');
        if (cartId) {
            cookies.remove('cart', { path: '/' });
            dispatch(setCartId(cartId));
        }
    }, []);

    // Get cart from cookies
    useEffect(() => {
        const { id, preview_token } = cookies.get('cart') || { id: undefined, preview_token: undefined };
        if (id) {
            dispatch(setCartId(id));
        }

        if (preview_token) {
            dispatch(setPreviewToken(preview_token));
        }
    }, [cookies, dispatch]);

    useEffect(() => {
        if (!edition || !edition.branding.color) return;
        document.documentElement.style.setProperty('--theme-color-primary', edition.branding.color);
        setPrimaryColor(edition.branding.color);
    }, [edition]);

    // Iframe implementation
    const storeWrapper = useRef<HTMLDivElement>(null);
    useIframe(storeWrapper);

    return (
        <>
            <Helmet>
                <title>EventSquare Store</title>
            </Helmet>
            <div ref={storeWrapper}>
                <EsqThemeProvider>
                    <ThemeProvider
                        theme={{
                            ...defaultTheme,
                            iframe: iframe,
                            colors: { ...defaultTheme.colors, primary: primaryColor },
                        }}
                    >
                        <GlobalStyle />
                        <BrowserRouter>
                            <Routes>
                                <Route path=":lang" element={<Event />}>
                                    <Route path=":editionUri" element={<Edition />}>
                                        <Route path=":channelUri" element={<Channel />}>
                                            <Route index element={<Flow />} />
                                            <Route path="shows" element={<FlowShows />} />
                                            <Route path="shows/:showId" element={<FlowShowId />} />
                                            <Route path="extras" element={<FlowExtras />} />
                                            <Route path="cart" element={<Cart />} />
                                            <Route path="info" element={<Info />} />
                                            <Route path="support" element={<Support />} />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path="/" element={<Redirect />} />
                            </Routes>
                        </BrowserRouter>
                    </ThemeProvider>
                </EsqThemeProvider>
            </div>
        </>
    );
};

//

export default App;

const Redirect = () => {
    devLog('redirect...');
    const navigate = useNavigate();
    const { lang } = useParams();
    const { search } = location;

    useEffect(() => {
        // Redirect to browser language when not set in params
        if (!lang) {
            const lang = navigator.language.slice(0, 2);
            devLog(`Redirecting to ${lang}`);
            navigate(`/${lang}${search}`, { replace: true });
        }
    }, [lang]);
    return (
        <>
            <h2>Redirecting....</h2>
        </>
    );
};
