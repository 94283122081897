import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { useAppSelector, useAppDispatch } from '@redux/hooks';
import { setActiveShow } from '@redux/slices/storeSlice';

import { EditTicketType, FlowType, ShowDetail, TicketType } from '@type/EditionTypes';

import { Tickets } from '@containers/Tickets';

import { areDatesLessThanDayApart, formatDate, getTypeMode } from '@lib/helpers';

import { Loader } from '@components/LoadingSpinner';
import { ChangeShowLink } from '@components/ChangeShowLink';
import { AddCouponLink } from '@components/modals/CouponModal';
import { Seatmap } from '@components/Seatmap';
import { api } from '@lib/api';

export const FlowShowId = () => {
    const { event, edition, cartId } = useAppSelector((state) => {
        return {
            event: state.store.event,
            edition: state.store.edition,
            cartId: state.store.cartId,
            cart: state.store.cart,
        };
    });
    const { lang, editionUri, channelUri, showId } = useParams();
    const [show, setShow] = useState<ShowDetail | undefined>(undefined);
    const [showTypes, setShowTypes] = useState<EditTicketType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(setActiveShow(undefined));
        };
    }, []);

    useEffect(() => {
        if (!event || !edition || !showId) return;

        const fetchShow = async () => {
            setIsLoading(true);
            try {
                const subdomain = event.uri;
                const params = {
                    cart: cartId,
                    language: lang,
                };
                const response = await api.get(`/store/${subdomain}/${editionUri}/${channelUri}/${showId}`, {
                    params,
                });
                const { show } = response.data;

                const showTypes = show.types.map((type: TicketType) => {
                    return { ...type, showId: showId };
                });
                setShow(response.data.show);
                dispatch(setActiveShow(response.data.show));
                setShowTypes(showTypes);
                setIsLoading(false);
            } catch (error: unknown) {
                if (axios.isAxiosError(error)) {
                    console.error(error.response?.data);
                    // TODO : setError or redirect?s
                    setIsLoading(false);
                }
            }
        };

        fetchShow();
    }, [event, edition, showId]);

    if (!event || !edition) return null;

    //type Flow = 'normal' | 'disabled' | 'grouped' | 'reservation';
    const flow: FlowType = edition.channel.flow;
    const changeShowUri =
        flow === 'normal' || flow === 'disabled' || (flow === 'grouped' && edition.channel.shows.length === 1)
            ? '/'
            : '/shows';

    const toCalendar = edition.channel.show_mode === 'calendar';

    const typeMode = getTypeMode(edition);

    const channelTypes: EditTicketType[] = edition.channel.types
        .filter((type) => {
            return typeMode === 'split' ? type.type === 'ticket' : true;
        })
        .map((type) => {
            return { ...type, showId: undefined };
        });

    const getShowSubtitle = () => {
        if (!show || !show.date.start) return null;
        if (show.date.start && show.date.end && !areDatesLessThanDayApart(show.date.start, show.date.end)) {
            return `${formatDate(show.date.start, show.date.hide_hours, lang)} - ${formatDate(
                show.date.end,
                show.date.hide_hours,
                lang,
            )}`;
        } else if (show.date.start) {
            return formatDate(show.date.start, show.date.hide_hours, lang);
        }
    };

    return (
        <>
            {(isLoading || !show) && <Loader />}
            {!isLoading && show && (
                <FlowWrapper>
                    <ChangeShowLink to={changeShowUri} toCalendar={toCalendar} />
                    <StyledShowDetail>
                        <ShowTitle>{show.name ? show.name : show.date.start ? getShowSubtitle() : show.id}</ShowTitle>
                        {show.name && show.date.start && <ShowDate>{getShowSubtitle()}</ShowDate>}
                    </StyledShowDetail>
                    <AddCouponLink cartId={cartId} brandingColor={edition.branding.color} showId={showId} />
                    {show.seatmaps.length > 0 && <Seatmap seatmapData={show.seatmaps[0]} show={show} />}
                    {show.seatmaps.length === 0 && (
                        <Tickets
                            types={flow === 'reservation' ? [...showTypes, ...channelTypes] : showTypes}
                            title={edition.title.types}
                            showId={show.id}
                            showDate={show.date}
                            showName={show.name}
                            hideTitle={true}
                        />
                    )}
                </FlowWrapper>
            )}
        </>
    );
};

const FlowWrapper = styled.div`
    margin-bottom: 1rem;
`;

const ShowTitle = styled.h2`
    font-weight: 800;
    font-size: 1.25rem;
`;

const ShowDate = styled.h3`
    /* font-style: italic; */
    font-variation-settings: 'ital' 125;
    color: ${(props) => props.theme.colors.fontLight};
    font-weight: 600;
    margin-top: 0.125rem;
`;

const StyledShowDetail = styled.div`
    margin-bottom: 1rem;
`;
