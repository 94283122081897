import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@redux/hooks';

import { EditTicketType } from '@type/EditionTypes';

import { Tickets } from '@containers/Tickets';

import { AddCouponLink } from '@components/modals/CouponModal';
import { getTypeMode } from '@lib/helpers';

export const FlowExtras: React.FC = () => {
    const { edition, cartId } = useAppSelector((state) => {
        return { edition: state.store.edition, cartId: state.store.cartId };
    });
    const [extrasTypes, setExtrasTypes] = useState<EditTicketType[]>([]);
    const { lang, editionUri, channelUri } = useParams();
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        if (!edition) return;
        const typeMode = getTypeMode(edition);

        // if typeMode is disabled redirect to /
        if (typeMode === 'disabled') {
            const url = `/${lang}/${editionUri}/${channelUri}`;
            navigate(url, { replace: true });
        }

        const types = edition.channel.types
            .filter((type) => {
                return type.type === 'voucher';
            })
            .map((type) => {
                return { ...type, showId: undefined };
            });

        if (types.length === 0) {
            const url = `/${lang}/${editionUri}/${channelUri}`;
            navigate(url, { replace: true });
        }

        setExtrasTypes(types);
    }, [edition]);

    if (!edition) return null;

    return (
        <>
            <AddCouponLink cartId={cartId} brandingColor={edition.branding.color} />

            <FlowWrapper>
                <Tickets types={extrasTypes} title={t('flow_extras.title')} hideTitle={false} />
            </FlowWrapper>
        </>
    );
};

const FlowWrapper = styled.div`
    margin-bottom: 1rem;
`;
