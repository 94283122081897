import { useCallback, useEffect, useRef } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { EventSquareEdition, FacebookIntegrationCredentials } from '@type/EditionTypes';
import { EventSquareEvent } from '@type/EventTypes';
import { useLocation } from 'react-router-dom';

export const useFBPixel = (event: EventSquareEvent | undefined, edition: EventSquareEdition | undefined) => {
    const initializedRef = useRef<boolean>(false);
    const location = useLocation();

    // Listen for Track events
    useEffect(() => {
        document.addEventListener('Track', handleTrack);
        return () => {
            document.removeEventListener('Track', handleTrack);
        };
    }, [event, edition]);

    // Initialize Facebook Pixel
    useEffect(() => {
        const fbIntegration = edition?.integrations.find((intergration) => intergration.type === 'facebook_pixel');
        if (initializedRef?.current || !edition || !fbIntegration) {
            return;
        }
        const { pixel_id } = fbIntegration.credentials as FacebookIntegrationCredentials;
        ReactPixel.init(pixel_id), null, { autoConfig: true, debug: true };
        initializedRef.current = true;
    }, [edition]);

    // Track pageviews
    useEffect(() => {
        if (!initializedRef?.current || !event || !edition) {
            return;
        }
        ReactPixel.pageView();
    }, [event, edition, location]);

    const handleTrack = useCallback(
        (data: any) => {
            if (!initializedRef?.current) {
                return;
            }
            switch (data.detail.type) {
                case 'AddToCart':
                    const category = event?.name + (edition?.name ? ` - ${edition?.name}` : '');
                    ReactPixel.track('AddToCart', {
                        content_ids: [data.detail.type_id],
                        content_type: 'product',
                        content_category: category,
                        content_name: data.detail.name,
                        value: data.detail.price,
                        currency: event?.currency,
                    });
                    break;
                default:
                    break;
            }
        },
        [event, edition],
    );
};
