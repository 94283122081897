import styled, { css } from 'styled-components';

import { Button } from '@components/Buttons';

interface EditButtonProps {
    buttonType: 'edit' | 'ghost' | 'text';
    onClick: () => void;
    children: React.ReactNode;
}

export const EditButton: React.FC<EditButtonProps> = (props) => {
    const { buttonType, onClick, children } = props;

    const handleOnClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClick();
    };

    return (
        <StyledEditButton buttonType={buttonType} onClick={handleOnClick}>
            {children}
        </StyledEditButton>
    );
};

const StyledEditButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    line-height: 1;
    min-width: 2.125rem;
    font-variant-numeric: proportional-nums;

    ${(props) =>
        props.buttonType === 'edit' &&
        css`
            padding: 0.5rem 0.75rem;
        `}
`;
