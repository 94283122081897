import styled from 'styled-components';
import { NavLinkProps, NavLink, useParams, To, Link as ReactLink } from 'react-router-dom';

import { useAppSelector } from '@redux/hooks';

export const AppLink = (props: NavLinkProps) => {
    const { children, to, className, ...rest } = props;
    const { edition } = useAppSelector((state) => {
        return { edition: state.store.edition };
    });
    const { lang } = useParams();

    const makeUrl = (to: To): string => {
        if (!edition || !lang) return String(to);
        return '/' + lang + '/' + edition.uri + '/' + edition.channel.uri + String(to);
    };

    if (className) {
        return (
            <NavLink className={className} to={makeUrl(to)} {...rest}>
                {children}
            </NavLink>
        );
    } else {
        return <ReactLink to={makeUrl(to)}>{children as React.ReactNode}</ReactLink>;
    }
};

export const Link = styled(AppLink)`
    font-size: inherit;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    transition: color 200ms ease;

    &:hover {
        color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
    }
`;
