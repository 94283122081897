import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Modal, ModalTitle, ModalText, ModalIcon } from '@components/modals/Modal';
import { Button } from '@components/Buttons';

interface EmptyCartModalProps {
    showModal: boolean;
    closeModal: () => void;
}

export const EmptyCartModal: React.FC<EmptyCartModalProps> = (props) => {
    const { showModal, closeModal } = props;
    const navigate = useNavigate();
    const { lang, editionUri, channelUri } = useParams();
    const { t } = useTranslation();

    const goToShop = () => {
        navigate(`/${lang}/${editionUri}/${channelUri}`);
        closeModal();
    };

    //if (!showModal) return null;

    return (
        <Modal closeModal={closeModal} center isVisible={showModal}>
            <ModalIcon name="shopping-cart-line" />
            <ModalTitle>{t('modal.empty_cart.title')}</ModalTitle>
            <ModalText>{t('modal.empty_cart.lead')}</ModalText>
            <Button fullWidth onClick={goToShop}>
                {t('modal.empty_cart.to_shop')}
            </Button>
        </Modal>
    );
};
