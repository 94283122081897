import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { reduxStore } from './redux/store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import './i18n';

const devMode = import.meta.env.VITE_APP_ENVIRONMENT !== 'production';

Sentry.init({
    dsn: devMode ? '' : 'https://66c3eb7f6cef4c4cae08592bb3af0e06@o543793.ingest.sentry.io/6489814',
});

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
    <StrictMode>
        <Suspense fallback={null}>
            <Provider store={reduxStore}>
                <App />
            </Provider>
        </Suspense>
    </StrictMode>,
);
