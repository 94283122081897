import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Organisation } from '@type/EventTypes';

interface OrganisationInfoProps {
    organisation: Organisation;
}

export const OrganisationInfo: React.FC<OrganisationInfoProps> = (props) => {
    const { organisation } = props;
    const { t } = useTranslation();
    return (
        <>
            <StyledTitle>{t('organisation_info.title')}</StyledTitle>
            <StyledInfo>
                <h5>{organisation.billing.company}</h5>
            </StyledInfo>
        </>
    );
};

const StyledTitle = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 1rem;
`;

const StyledInfo = styled.div`
    color: ${(props) => props.theme.colors.fontMedium};

    h5 {
        margin-bottom: 0.25rem;
        font-weight: 600;
    }
    p {
        line-height: 1.2;

        span {
            display: inline-block;
            margin-right: 0.25rem;
            position: relative;
            bottom: -0.25rem;
        }

        a {
            text-decoration: none;
            color: inherit;
            transition: all 200ms ease;

            &:hover {
                color: ${(props) => props.theme.colors.primary};
            }
        }
    }
`;
