import axios, { AxiosInstance } from 'axios';

class ApiClient {
    static config = {
        environment: 'development',
        apiEndpoint: import.meta.env.VITE_API_ENDPOINT ?? 'https://api.eventsquare.io/1.0',
        apiKey: import.meta.env.VITE_API_KEY ?? '0QWp7zq26LRrYDq7gz5BKY80zLOFjOJJ',
    };

    static create(): AxiosInstance {
        return axios.create({
            baseURL: this.config.apiEndpoint,
            timeout: 30000,
            headers: {
                apikey: this.config.apiKey,
            },
        });
    }
}

export const api = ApiClient.create();
