import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { api } from '@lib/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EditTicketType, EventSquareEdition, TypeModeType } from '@type/EditionTypes';
import { EventSquareCart } from '@type/CartTypes';
import type { Date } from '@type/EventTypes';

import { Modal, ModalError, ModalTitle } from '@components/modals/Modal';
import { Input } from '@components/FormElements';
import { Button } from '@components/Buttons';
import { Icon } from '@components/Icon';
import { Markdown } from '@components/Markdown';

import { getQuantity, formatCurrency, formatDate, getDiscountPrice } from '@lib/helpers';

import { useAppDispatch } from '@redux/hooks';
import { setCart } from '@redux/slices/storeSlice';
import { Currency } from '@type/CurrencyTypes';

interface EditTypeModalProps {
    type: EditTicketType | undefined;
    // type: TicketType | undefined;
    closeModal: () => void;
    cart: EventSquareCart | undefined;
    isOnCartPage?: boolean;
    // showId?: string | undefined;
    showDate?: Date;
    showName?: string | undefined;
    typeMode: TypeModeType;
    currency: Currency;
    edition: EventSquareEdition | undefined;
}

const emptyShowDate: Date = {
    doors: null,
    start: null,
    end: null,
    hide_hours: false,
};

export const EditTypeModal: React.FC<EditTypeModalProps> = (props) => {
    const {
        type,
        closeModal,
        cart,
        isOnCartPage = false,
        // showId = undefined,
        showDate = emptyShowDate,
        showName = undefined,
        typeMode,
        currency,
        edition,
    } = props;

    const { lang, editionUri, channelUri } = useParams();

    const originalQuantity = getQuantity(type, cart, type?.showId);
    const [quantity, setQuantity] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editTypeError, setEditTypeError] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isVisible = !!type && !!cart && !!lang && quantity !== undefined;

    useEffect(() => {
        setQuantity(type ? getQuantity(type, cart, type.showId) : undefined);
    }, [type]);

    const closeModalAndClearQuantity = () => {
        closeModal();
        setQuantity(undefined);
        setEditTypeError(undefined);
    };

    const changeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!type || quantity === undefined) return;
        setQuantity(+e.target.value);
    };

    const minusOne = () => {
        if (!type || quantity === undefined) return;
        if (quantity === type.min_pp) {
            setQuantity(0);
            return;
        }
        if (quantity > 0) {
            setQuantity((prevQuantity) => (prevQuantity ? prevQuantity - 1 : 0));
        }
    };

    const plusOne = () => {
        if (!type || quantity === undefined) return;
        if (quantity === 0) {
            setQuantity(type.min_pp);
            return;
        }
        if (quantity < type.max_pp) {
            setQuantity((prevQuantity) => (prevQuantity ? prevQuantity + 1 : 1));
        }
    };

    const editCart = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent, openCart?: boolean) => {
        e.preventDefault();
        if (!isVisible) return;
        setEditTypeError(undefined);
        if (quantity && quantity < type.min_pp) {
            setEditTypeError('minimum_quantity');
            return;
        }
        setIsLoading(true);

        // if original quantity == new quantity , just close
        if (quantity == originalQuantity) {
            setIsLoading(false);
            closeModalAndClearQuantity();
            if (openCart) {
                navigate(`/${lang}/${editionUri}/${channelUri}/cart`);
            }
        }

        try {
            const data: { quantity: number; show?: string; language: string; deal?: string } = {
                quantity: quantity - originalQuantity,
                language: lang,
            };
            if (type.showId) {
                data['show'] = type.showId;
            }
            if (type.dealId) {
                data['deal'] = type.dealId;
            }
            const response = await api.put(`/cart/${cart.cartid}/types/${type.id}`, data);

            // Dispatch event to track in Google Analytics, Facebook Pixel and TikTok Pixel
            document.dispatchEvent(
                new CustomEvent('Track', {
                    detail: {
                        type: 'AddToCart',
                        type_id: type.id,
                        name: type.name,
                        price: type.price * data.quantity,
                    },
                }),
            );
            dispatch(setCart(response.data.cart));
            setIsLoading(false);
            closeModalAndClearQuantity();
            if (openCart) {
                navigate(`/${lang}/${editionUri}/${channelUri}/cart`);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.response?.data);
                setEditTypeError(error.response?.data.error);
                setQuantity(originalQuantity);
                setIsLoading(false);
            }
        }
    };

    const getStatusMessage = (type: EditTicketType) => {
        const now = +new Date();
        if (type.sales.start && +new Date(type.sales.start) > now) {
            return t('modal.edit_type.available_from', { date: formatDate(type.sales.start, false, 'nl') });
        }

        if (type.sales.end && +new Date(type.sales.end) < now) {
            return t('modal.edit_type.no_more_available');
        }

        return null;
    };

    const priceWithDiscount = getDiscountPrice(type, cart);

    return (
        <Modal closeModal={closeModalAndClearQuantity} loading={isLoading} isVisible={isVisible}>
            {isVisible && (
                <>
                    <ModalTitle>{type.name}</ModalTitle>

                    {showName && <StyledShowName>{showName}</StyledShowName>}
                    {showDate.start && <StyledDate>{formatDate(showDate.start, showDate.hide_hours, lang)}</StyledDate>}
                    {!showDate.start && type.date.start && (
                        <StyledDate>{formatDate(type.date.start, type.date.hide_hours, lang)}</StyledDate>
                    )}
                    <StyledHr />
                    {!type.sales.status && <StyledStatusMessage>{getStatusMessage(type)}</StyledStatusMessage>}
                    {type.sales.status && type.status === 'soldout' && (
                        <StyledStatusMessage>{t('modal.edit_type.soldout')}</StyledStatusMessage>
                    )}
                    <Markdown>{type.description}</Markdown>
                    <StyledPrice>
                        {!!priceWithDiscount
                            ? formatCurrency(priceWithDiscount, lang, currency)
                            : t('modal.edit_type.free')}
                        {!!type.price && priceWithDiscount < type.price && (
                            <span className="original-price">{formatCurrency(type.price, lang, currency)}</span>
                        )}
                    </StyledPrice>
                    {!!type.fee && !!priceWithDiscount && (
                        <StyledFee>
                            {t('modal.edit_type.fee', { fee: formatCurrency(type.fee, lang, currency) })}
                        </StyledFee>
                    )}
                    {!!edition?.channel.hide_vat && <StyledFee>{t('modal.edit_type.VAT_excl')}</StyledFee>}
                    {type.sales.status && type.status !== 'soldout' && (
                        <>
                            {type.min_pp > 1 && (
                                <StyledMessage>{t('modal.edit_type.min_pp', { min: type.min_pp })}</StyledMessage>
                            )}
                            {type.status === 'low' && (
                                <StyledMessage>
                                    {type.stock
                                        ? t('modal.edit_type.status_low_stock', { stock: type.stock })
                                        : t('modal.edit_type.status_low')}
                                </StyledMessage>
                            )}
                            <form onSubmit={editCart}>
                                <StyledEditQuantity>
                                    <StyledEditButton type="button" onClick={minusOne} disabled={quantity === 0}>
                                        <Icon name="subtract-fill" />
                                    </StyledEditButton>
                                    <StyledEditInput
                                        type="number"
                                        value={quantity}
                                        onChange={changeQuantity}
                                        name="type_quality"
                                        min={0}
                                        max={type.max_pp}
                                    />
                                    <StyledEditButton
                                        type="button"
                                        onClick={plusOne}
                                        disabled={quantity === type.max_pp}
                                    >
                                        <Icon name="add-fill" />
                                    </StyledEditButton>
                                </StyledEditQuantity>
                                {editTypeError && (
                                    <ModalError onClick={() => setEditTypeError(undefined)}>
                                        {t(`modal.edit_type.error.${editTypeError}`, {
                                            name: type.name,
                                            max: type.max_pp,
                                        })}
                                    </ModalError>
                                )}
                                <StyledActionButtons>
                                    <Button type="submit" fullWidth>
                                        {originalQuantity === quantity
                                            ? t('modal.edit_type.close')
                                            : originalQuantity === 0
                                            ? t('modal.edit_type.add')
                                            : t('modal.edit_type.edit')}
                                    </Button>
                                    {!isOnCartPage && typeMode !== 'split' && (
                                        <Button
                                            type="button"
                                            buttonType="text"
                                            fullWidth
                                            onClick={(e: React.MouseEvent) => {
                                                editCart(e, true);
                                            }}
                                        >
                                            {originalQuantity === quantity
                                                ? t('modal.edit_type.close_to_cart')
                                                : originalQuantity === 0
                                                ? t('modal.edit_type.add_to_cart')
                                                : t('modal.edit_type.edit_to_cart')}
                                        </Button>
                                    )}
                                </StyledActionButtons>
                            </form>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

const StyledShowName = styled.h5`
    font-size: 1rem;
    font-weight: 800;
    color: ${(props) => props.theme.colors.fontDark};
`;

const StyledDate = styled.h6`
    font-size: 0.875rem;
    /* font-style: italic; */
    font-variation-settings: 'ital' 125;
    font-weight: 600;
    color: ${(props) => props.theme.colors.fontLight};
    margin-top: 0.125rem;
    margin-bottom: 0.75rem;
`;

const StyledHr = styled.hr`
    margin-bottom: 0.75rem;
    border: 0;
    height: 1px;
    background-color: ${(props) => props.theme.colors.lines};
`;

const StyledStatusMessage = styled.p`
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.fontDark};
`;

const StyledPrice = styled.p`
    font-size: 1.25rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.fontDark};

    span.original-price {
        display: inline-block;
        margin-left: 0.375rem;
        color: ${(props) => props.theme.colors.fontLight};
        font-size: 1rem;
        font-weight: 400;
        text-decoration: line-through;
    }
`;

const StyledFee = styled.p`
    font-size: 0.75rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.fontLight};
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
`;

const StyledMessage = styled.p`
    color: ${(props) => props.theme.colors.fontMedium};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75rem;
    margin-top: 0.25rem;
`;

const StyledEditButton = styled(Button)`
    font-size: 1.75rem;
    border-radius: 50%;
    aspect-ratio: 1;
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    box-shadow: none;
`;

const StyledEditInput = styled(Input)`
    width: 6rem;
    margin: 0 2rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0.75rem;
`;

const StyledEditQuantity = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
`;

const StyledActionButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
`;
