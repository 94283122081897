import { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@redux/hooks';

import { Tickets } from '@containers/Tickets';
import { Shows } from '@containers/Shows';

import { EditTicketType, FlowType } from '@type/EditionTypes';

import { AppLink } from '@components/Link';
import { Icon } from '@components/Icon';
import { EmptyTickets } from '@components/EmptyStates';
import { AddCouponLink } from '@components/modals/CouponModal';
import { getTypeMode } from '@lib/helpers';

export const Flow = () => {
    const { event, edition, cartId, cart } = useAppSelector((state) => {
        return {
            event: state.store.event,
            edition: state.store.edition,
            cartId: state.store.cartId,
            cart: state.store.cart,
        };
    });
    const { lang, editionUri, channelUri } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!edition) return;
        if (edition.channel.flow === 'reservation') {
            // Redirect to /shows if show mode = 'reservation'
            const url = `/${lang}/${editionUri}/${channelUri}/shows`;
            navigate(url, { replace: true });
        }
    }, [edition]);

    // Rename ShopContainer > FlowContainer

    // Normal - disabled Flow
    // ShowContainer + TicketsModule

    // Grouped flow
    // Fakebutton to /shows + TicketsModule
    // On page /shows => shows ShowContainer
    // /shows should only be accesible if flow is grouped

    // Reservation flow
    // Redirect to /shows in UseEffect on top

    // ShowContainer - renders differently depending on show mode:
    // List: simple list of all shows
    // Auto: chooses best option between list or calendar view
    // Calendar: show calendar, on clicking day show timelosts or opens show (when only one timeslot on day)

    if (!event || !edition || !cartId || !cart) return null;

    //type Flow = 'normal' | 'disabled' | 'grouped' | 'reservation';
    const flow: FlowType = edition.channel.flow;
    const typeMode = getTypeMode(edition);
    const channelTypes = edition.channel.types
        .filter((type) => {
            return typeMode === 'split' ? type.type === 'ticket' : true;
        })
        .map((type) => {
            return { ...type, showId: undefined };
        }) as EditTicketType[];

    const unlockedTypes = cart.deals
        .filter((deal) => {
            return deal.rewards.length > 0;
        })
        .map((deal) => {
            const rewards = deal.rewards
                .filter((reward) => {
                    return reward.reward === 'unlock_type';
                })
                .map((reward) => {
                    return { ...reward.type, showId: undefined, dealId: deal.id };
                });
            return rewards;
        })
        .flat() as EditTicketType[];

    return (
        <FlowWrapper>
            <AddCouponLink cartId={cartId} brandingColor={edition.branding.color} />

            {
                /* if grouped => Link to /shows */
                flow === 'grouped' && edition.channel.shows.length > 0 && (
                    <GroupedShowsLink>
                        {edition.channel.shows.length > 1 && (
                            <AppLink to="/shows">
                                <StyledShowTitle>{edition.title.shows || t('flow.shows')}</StyledShowTitle>
                                <StyledIcon>
                                    <Icon name="arrow-right-s-line" />
                                </StyledIcon>
                            </AppLink>
                        )}
                        {edition.channel.shows.length === 1 && (
                            <AppLink to={`/shows/${edition.channel.shows[0].id}`}>
                                <StyledShowTitle>{edition.title.shows || t('flow.show')}</StyledShowTitle>
                                <StyledIcon>
                                    <Icon name="arrow-right-s-line" />
                                </StyledIcon>
                            </AppLink>
                        )}
                    </GroupedShowsLink>
                )
            }
            {(flow === 'normal' || flow === 'disabled') && edition.channel.shows.length > 0 && (
                <Shows
                    shows={edition.channel.shows}
                    title={edition.title.shows}
                    show_mode={edition.channel.show_mode}
                    lang={lang}
                    hideTitle={false}
                />
            )}
            {(unlockedTypes.length > 0 || channelTypes.length > 0) && (
                <Tickets
                    types={[...unlockedTypes, ...channelTypes]}
                    // title={edition.title.types}
                    title={edition.title.types}
                    hideTitle={edition.channel.shows.length === 0 || channelTypes.length === 0}
                />
            )}

            {edition.channel.types.length === 0 && edition.channel.shows.length === 0 && (
                <>
                    <EmptyTickets />
                </>
            )}
        </FlowWrapper>
    );
};

const FlowWrapper = styled.div`
    margin-bottom: 1rem;
`;

const GroupedShowsLink = styled.div`
    margin-bottom: 1.5rem;
    padding: 0 0 0.75rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.lines};

    a {
        padding: 0.75rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: inherit;
        transition: all 200ms ease;

        &:hover {
            padding-left: 0.75rem;
        }
    }
`;

const StyledShowTitle = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
`;

const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.primary};
`;
