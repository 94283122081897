import { useState } from 'react';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EditTicketType } from '@type/EditionTypes';
import type { Date } from '@type/EventTypes';

import { getQuantity, formatCurrency, formatDate, getDiscountPrice, getTypeMode } from '@lib/helpers';

import { useAppSelector } from '@redux/hooks';

import { EditButton } from '@components/EditButton';
import { Icon } from '@components/Icon';
import { EditTypeModal } from '@components/modals/EditTypeModal';
import { EmptyTickets } from '@components/EmptyStates';

interface TicketsModuleProps {
    types: EditTicketType[];
    title: string | null;
    showId?: string | undefined;
    showDate?: Date | undefined;
    showName?: string | undefined;
    hideTitle: boolean;
}

interface TypeItemProps {
    brandingColor: string;
}

export const Tickets: React.FC<TicketsModuleProps> = (props) => {
    const { types, title, showId, showDate, showName, hideTitle } = props;
    const { cart, edition, event } = useAppSelector((state) => {
        return { cart: state.store.cart, edition: state.store.edition, event: state.store.event };
    });
    const [activeType, setActiveType] = useState<EditTicketType | undefined>(undefined);
    const { lang } = useParams();
    const { t } = useTranslation();

    const editType = (type: EditTicketType) => {
        setActiveType(type);
    };
    const closeModal = () => {
        setActiveType(undefined);
    };

    const typeMode = getTypeMode(edition);

    const getTypeMessage = (type: EditTicketType) => {
        if (!type.sales.status) {
            const now = +new Date();
            if (type.sales.start && +new Date(type.sales.start) > now) {
                return t('tickets.message.available_from', { date: formatDate(type.sales.start, false, 'nl') });
            }

            if (type.sales.end && +new Date(type.sales.end) < now) {
                return t('tickets.message.no_more_available');
            }
        }

        if (type.status === 'low') {
            return type.stock ? t('tickets.message.stock', { stock: type.stock }) : t('tickets.message.status_low');
        }

        if (type.stock) {
            return t('tickets.message.stock', { stock: type.stock });
        }

        return null;
    };

    if (!event) {
        return null;
    }

    if (types.length === 0 && typeMode !== 'split') {
        return <EmptyTickets showId={showId} />;
    }
    // if showId don't show title
    return (
        <>
            {!hideTitle && <StyledTicketsTitle>{title ?? t('tickets.title')}</StyledTicketsTitle>}
            <StyledTicketList>
                {types.map((type) => {
                    // type.status === 'available' | 'soldout' | 'low' | 'not_available'
                    const typeQuantity = getQuantity(type, cart, type.showId);
                    const priceWithDiscount = getDiscountPrice(type, cart);
                    const message = getTypeMessage(type);
                    return (
                        <StyledTicketItem key={type.id + '1'} brandingColor={type.branding.color}>
                            <StyledTicketBox>
                                <div onClick={() => editType(type)}>
                                    <StyledTicketName> {type.name} </StyledTicketName>
                                    <StyledTicketPrice>
                                        {!!priceWithDiscount
                                            ? formatCurrency(priceWithDiscount, lang, event.currency)
                                            : t('tickets.free')}
                                        {!!type.price && priceWithDiscount < type.price && (
                                            <span className="original-price">
                                                {formatCurrency(type.price, lang, event.currency)}
                                            </span>
                                        )}
                                        {!!type.fee && !!priceWithDiscount && (
                                            <span className="fee">
                                                {t('tickets.fee', {
                                                    fee: formatCurrency(type.fee, lang, event.currency),
                                                })}
                                            </span>
                                        )}
                                        {!!type.price && edition?.channel.hide_vat && (
                                            <span className="vat_info">{t('tickets.VAT_excl')}</span>
                                        )}
                                    </StyledTicketPrice>
                                </div>
                                {(type.status === 'available' || type.status === 'low') && (
                                    <EditButton
                                        buttonType={typeQuantity ? 'edit' : 'ghost'}
                                        onClick={() => editType(type)}
                                    >
                                        {typeQuantity ? typeQuantity : <Icon name="add-fill" />}
                                    </EditButton>
                                )}
                                {type.status === 'soldout' && (
                                    <EditButton buttonType="text" onClick={() => editType(type)}>
                                        {t('tickets.soldout')}
                                    </EditButton>
                                )}
                                {type.status === 'not_available' && (
                                    <EditButton buttonType="text" onClick={() => editType(type)}>
                                        {t('tickets.info')}
                                    </EditButton>
                                )}
                            </StyledTicketBox>
                            {message && <StyledTicketInfo>{message}</StyledTicketInfo>}
                        </StyledTicketItem>
                    );
                })}
            </StyledTicketList>
            <EditTypeModal
                type={activeType}
                closeModal={closeModal}
                cart={cart}
                //showId={showId}
                showDate={showDate}
                showName={showName}
                typeMode={getTypeMode(edition)}
                currency={event.currency}
                edition={edition}
            />
        </>
    );
};

const StyledTicketsTitle = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    color: var(--theme-form-label);
    font-size: var(--theme-font-size-min-1);
    margin-bottom: 0.5rem;
`;

const StyledTicketList = styled.ul``;

const StyledTicketItem = styled.li<TypeItemProps>`
    padding: 1rem 0 1rem 1rem;
    border-bottom: 1px solid var(--theme-lines);
    position: relative;

    &:last-of-type {
        border-bottom: none;
    }

    ${(props) =>
        props.brandingColor &&
        css`
            &:before {
                content: '';
                height: calc(100% - 2rem);
                width: 0.25rem;
                border-radius: 0.125rem;
                position: absolute;
                top: 1rem;
                left: 0;
                background-color: ${props.brandingColor};
            }
        `}
`;

const StyledTicketBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
`;

const StyledTicketName = styled.h5`
    font-size: 1.125rem;
    font-weight: 800;
    margin-bottom: 0.25rem;
    color: var(--theme-font-color-heading);
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
    }
`;
const StyledTicketPrice = styled.h6`
    font-size: 1.125rem;
    font-weight: 400;
    color: var(--theme-font-color);

    span.original-price {
        display: inline-block;
        margin-left: 0.375rem;
        color: var(--theme-font-color);
        font-size: 1rem;
        text-decoration: line-through;
    }

    span.fee,
    span.vat_info {
        display: inline-block;
        margin-left: 0.25rem;
        color: var(--theme-font-color-subtle);
        font-size: 0.75rem;
    }
`;
const StyledTicketInfo = styled.p`
    text-transform: uppercase;
    color: var(--theme-font-color-subtle);
    font-weight: 700;
    font-size: 0.75rem;
    margin-top: 0.5rem;
`;
