import { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation, useSearchParams } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { fetchCart } from '@redux/slices/storeSlice';

import { Branding, TypeModeType } from '@type/EditionTypes';

import { getCartQuantity, getYearNow, getTypeMode } from '@lib/helpers';

import { ChannelTabs } from '@components/ChannelTabs';
import { ChannelNav } from '@components/ChannelNav';
import { ChannelInfo } from '@components/ChannelInfo';
import { GoToNextPageButton } from '@components/GoToNextPageButton';
import { MobileNav } from '@components/MobileNav';
import { Helmet } from 'react-helmet';
import { Loader } from '@components/LoadingSpinner';
import { TestModeWarning } from '@components/TestModeWarning';
import { ExpiredCartModal } from '@components/modals/ExpiredCartModal';
import { CartExpirationWarningModal } from '@components/modals/CartExpirationWarningModal';

export const Channel = () => {
    const dispatch = useAppDispatch();
    const { event, edition, cartId, cartExpiration, iframe, cart, previewToken, activeShow } = useAppSelector(
        (state) => {
            return {
                event: state.store.event,
                edition: state.store.edition,
                cartId: state.store.cartId,
                cartExpiration: state.store.cartExpiration,
                iframe: state.store.iframe,
                cart: state.store.cart,
                previewToken: state.store.previewToken,
                activeShow: state.store.activeShow,
            };
        },
    );

    const { lang } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const pathName = location.pathname.split('/');
    const { t } = useTranslation();

    const calculateTimeLeft = () => {
        if (!cartExpiration) return 900;

        const now = new Date();
        const difference = Math.round((cartExpiration - now.getTime()) / 1000);

        return difference;
    };

    const showOnMobile = !(
        (pathName[4] === 'shows' && edition?.channel.flow !== 'reservation') ||
        pathName[4] === 'cart' ||
        pathName[4] === 'info' ||
        pathName[4] === 'support'
    );

    const [cartQuantity, setCartQuantity] = useState<number>(0);
    const [showExpiredCartModal, setShowExpiredCartModal] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(calculateTimeLeft());
    const [typeMode, setTypeMode] = useState<TypeModeType | null>(null);
    const [hasBottomButtonOnMobile, setHasBottomButtonOnMobile] = useState<boolean>(false);

    useEffect(() => {
        const mode = getTypeMode(edition);
        setTypeMode(mode);

        if (edition?.channel.store && searchParams.get('preview_token')) {
            searchParams.delete('preview_token');
            setSearchParams(searchParams);
        }

    }, [edition]);

    useEffect(() => {
        if (!cartId) return;
        dispatch(fetchCart(lang));
    }, [cartId, lang]);

    useEffect(() => {
        if (!cart) setCartQuantity(0);
        setCartQuantity(getCartQuantity(cart));
    }, [cart]);

    useEffect(() => {
        if (!cart || !edition) return;
        if (cart.pending) {
            const storeUrl = previewToken
                ? `${edition.channel.url}&preview_token=${previewToken}`
                : edition.channel.url;
            const fastlaneUrl = `${cart.fastlane_url}?s=${encodeURIComponent(storeUrl)}`;
            iframe ? window.open(fastlaneUrl) : (window.location.href = fastlaneUrl);
        }
    }, [cart, edition]);

    useEffect(() => {
        if (timeLeft < 1) {
            setShowExpiredCartModal(true);
            setTimeLeft(0);
            return;
        }

        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [cartExpiration, timeLeft]);

    // If  page is shown from BFF cache, then fetch cart to make sure that we have the correct pending value
    useEffect(() => {
        window.addEventListener(
            'pageshow',
            (e) => {
                if (e.persisted) {
                    dispatch(fetchCart(lang));
                }
            },
            false,
        );
    }, []);

    useEffect(() => {
        if (cartQuantity > 0 || typeMode === 'split') {
            setHasBottomButtonOnMobile(true);
        } else {
            setHasBottomButtonOnMobile(false);
        }
    }, [cartQuantity, typeMode]);

    if (!event || !edition || !cartId || !cart) {
        return (
            <StyledLoadingPage>
                <Loader white />
            </StyledLoadingPage>
        );
    }

    const metaTitle = `${event.name} ${edition.name ? ` ${edition.name} ` : ''}| EventSquare`;
    const hasInfo = !!edition.description || !!edition.venue;
    const seatmapActive = activeShow ? activeShow.seatmaps.length > 0 : false;
    const banner = edition.channel.branding.banner ?? edition.branding.banner ?? null;
    const testmode = !!(previewToken && !edition.channel.store);

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={`${edition.introduction}`} />
                <link rel="canonical" href={edition.channel.url} />
            </Helmet>
            <MobileNav languages={event.languages} hasInfo={hasInfo} testmode={!!testmode} />
            <ChannelWrapper branding={edition.branding}>
                {edition.branding.logo && !banner && !iframe && (
                    <EditionLogo showOnMobile={showOnMobile}>
                        <img src={edition.branding.logo} alt={edition.name} />
                    </EditionLogo>
                )}
                <ChannelBox>
                    {banner && !iframe && (
                        <ChannelBanner showOnMobile={showOnMobile}>
                            <img src={banner} alt={`${edition.name} banner`} className="banner" />
                            {edition.branding.logo && (
                                <div className="logo-wrapper">
                                    <img src={edition.branding.logo} alt={edition.name} className="logo" />
                                </div>
                            )}
                        </ChannelBanner>
                    )}
                    <ChannelBoxGrid hasBanner={!!banner}>
                        <ChannelTabs
                            fastLane={cart.fastlane_url}
                            cartQuantity={cartQuantity}
                            hasBanner={!!banner}
                            flow={edition.channel.flow}
                            typeMode={typeMode}
                            previewToken={previewToken}
                            channelUrl={edition.channel.url}
                            iframe={iframe}
                        />
                        {!iframe && <ChannelNav languages={event.languages} hasInfo={hasInfo} />}
                        <ChannelOutlet hasBottomButtonOnMobile={hasBottomButtonOnMobile} seatmapActive={seatmapActive}>
                            <ChannelOutletPadding hasBottomButtonOnMobile={hasBottomButtonOnMobile}>
                                <Outlet />
                            </ChannelOutletPadding>
                            <StyledGoToNextPageButtonWrapper hasBottomButtonOnMobile={hasBottomButtonOnMobile}>
                                <GoToNextPageButton
                                    cartQuantity={cartQuantity}
                                    fastLane={cart.fastlane_url}
                                    previewToken={previewToken}
                                    timeLeft={timeLeft}
                                    channelUrl={edition.channel.url}
                                    iframe={iframe}
                                    typeMode={typeMode}
                                />
                            </StyledGoToNextPageButtonWrapper>
                            {!iframe && (
                                <StyledDisclaimerBox>
                                    <StyledDisclaimerOrganisation>{`© ${getYearNow()} ${
                                        event.organisation.name
                                    }`}</StyledDisclaimerOrganisation>
                                    <StyledDisclaimerList>
                                        <StyledDisclaimerListItem>
                                            <a
                                                href="https://www.eventsquare.co/docs/conditions"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {t('channel.terms_conditions')}
                                            </a>
                                        </StyledDisclaimerListItem>
                                        <StyledDisclaimerListItem>
                                            <a
                                                href="https://www.eventsquare.co/docs/privacy"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {t('channel.privacy')}
                                            </a>
                                        </StyledDisclaimerListItem>
                                        <StyledDisclaimerListItem>
                                            <a
                                                href="https://www.eventsquare.co/docs/refunds"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {t('channel.renunciation_right')}
                                            </a>
                                        </StyledDisclaimerListItem>
                                    </StyledDisclaimerList>
                                </StyledDisclaimerBox>
                            )}
                        </ChannelOutlet>
                        {!iframe && (
                            <ChannelInfo
                                lang={lang}
                                cartQuantity={cartQuantity}
                                showOnMobile={showOnMobile}
                                previewToken={previewToken}
                                timeLeft={timeLeft}
                                seatmapActive={seatmapActive}
                            />
                        )}
                    </ChannelBoxGrid>
                    <ESQLabel>
                        <a href="https://eventsquare.co" target="_blank" rel="noreferrer">
                            <img src="/esq-logo-white.svg" alt="EventSquare" />
                        </a>
                    </ESQLabel>
                </ChannelBox>
            </ChannelWrapper>
            <ExpiredCartModal
                showModal={showExpiredCartModal}
                closeModal={() => setShowExpiredCartModal(false)}
                cartTimeout={edition.cart.cart_timeout}
            />
            <CartExpirationWarningModal timeLeft={timeLeft} cartTimeout={edition.cart.cart_timeout} />
            {testmode && <TestModeWarning previewToken={previewToken} />}
        </>
    );
};

const StyledLoadingPage = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--theme-color-primary);
    transition: background-color ease 0.2s;
`;

const ChannelWrapper = styled.div<{ branding: Branding }>`
    width: 100%;

    ${(props) =>
        !props.theme.iframe &&
        css`
            padding: 6rem 1.5rem;
            padding-bottom: 6rem;
            min-height: 100vh;
            background-color: ${props.branding.background.color || 'var(--theme-canvas)'};

            ${props.branding.background.image &&
            css`
                background-image: url(${props.branding.background.image});
                background-repeat: no-repeat;
                background-attachment: fixed;
                background-position: ${props.branding.background.position.x} ${props.branding.background.position.y};
                background-size: ${props.branding.background.size};
            `}
        `};

    @media (max-width: 57em) {
        padding: 0;
        min-height: unset;
    }

    ${(props) =>
        props.theme.iframe &&
        css`
            padding-top: 2rem;

            @media (max-width: 57em) {
                padding: 2rem 0 0;
                /* min-height: 100vh; */
            }
        `}
`;

const ChannelBox = styled.div`
    width: 100%;
    max-width: 62.5rem;
    margin: 0 auto;
    border-radius: ${(props) => (props.theme.iframe ? 'none' : '1rem')};
    box-shadow: var(--theme-box-shadow-high);
    position: relative;
    z-index: 50;

    @media (max-width: 57em) {
        border-radius: 0;
        box-shadow: none;
    }

    ${(props) =>
        props.theme.iframe &&
        css`
            box-shadow: none;
            max-width: unset;
        `}
`;

const ChannelBanner = styled.div<{ showOnMobile: boolean }>`
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
    background-color: transparent;
    position: relative;
    aspect-ratio: 25/11;

    div.logo-wrapper {
        width: 80%;
        height: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;

        img.logo {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    @media (max-width: 57em) {
        border-radius: 0;
        display: ${(props) => (props.showOnMobile ? 'block' : 'none')};
    }
`;

const ESQLabelSlideUp = keyframes`
    0% { bottom: 90%; }
    100% { bottom: 100%; }
`;

const ESQLabel = styled.div`
    position: absolute;
    right: 1.5rem;
    bottom: 100%;
    padding: 0.5rem 0.75rem;
    background-color: var(--theme-color-primary);
    border-radius: 0.5rem 0.5rem 0 0;
    box-shadow: var(--theme-box-shadow-medium);
    z-index: -1;

    animation-name: ${ESQLabelSlideUp};
    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;

    img {
        width: 7rem;
    }

    ${(props) =>
        !props.theme.iframe &&
        css`
            @media (max-width: 57em) {
                display: none;
            }
        `}
`;

const ChannelBoxGrid = styled.div<{ hasBanner: boolean }>`
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 0;
    position: relative;
    z-index: 0;
    background-color: var(--theme-card);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    ${(props) =>
        props.hasBanner &&
        css`
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        `}

    ${(props) =>
        props.theme.iframe &&
        css`
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        `}

    @media (max-width: 57em) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

const ChannelOutlet = styled.div<{ hasBottomButtonOnMobile: boolean; seatmapActive: boolean }>`
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    transition: padding 0.2s ease;

    @media (max-width: 57em) {
        padding: ${(props) => (props.hasBottomButtonOnMobile ? '1.5rem 1.5rem 5rem' : '1.5rem')};
    }
    ${(props) =>
        props.seatmapActive &&
        css`
            grid-column: 1 / span 2;

            @media (max-width: 57em) {
                grid-column: 1 / span 1;
            }
        `}
`;

const ChannelOutletPadding = styled.div<{ hasBottomButtonOnMobile: boolean }>`
    /* padding: 2.5rem;

    @media (max-width: 57em) {
        padding: ${(props) => (props.hasBottomButtonOnMobile ? '1.5rem 1.5rem 5rem' : '1.5rem')};
    } */
`;

const StyledDisclaimerBox = styled.div`
    font-size: 0.75rem;
    margin-top: auto;
    padding-top: 3rem;
    text-align: center;
    color: var(--theme-font-color-subtle);
`;

const StyledDisclaimerOrganisation = styled.h6`
    font-weight: 600;
    margin-bottom: 0.25rem;
`;

const StyledDisclaimerList = styled.ul`
    @media (max-width: 57em) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: var(--theme-font-color-subtle);
    }
`;

const StyledDisclaimerListItem = styled.li`
    display: inline;
    text-align: center;

    &:not(:last-of-type) {
        margin-right: 1rem;
        position: relative;

        &:after {
            content: '|';
            position: absolute;
            right: -0.55rem;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;

const EditionLogo = styled.div<{ showOnMobile: boolean }>`
    width: 100%;
    padding: 2rem 2rem 4rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: block;
        width: auto;
        height: auto;
        max-width: 80%;
        max-height: 15rem;
    }

    @media (max-width: 57em) {
        display: ${(props) => (props.showOnMobile ? 'flex' : 'none')};
        padding: 2rem;
    }
`;

const StyledGoToNextPageButtonWrapper = styled.div<{ hasBottomButtonOnMobile: boolean }>`
    display: none;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.75rem 0.75rem;
    z-index: 100;
    transition: bottom 300ms ease;

    ${(props) =>
        props.theme.iframe &&
        css`
            display: block;
        `}

    ${(props) =>
        !props.theme.iframe &&
        css`
            @media (max-width: 57em) {
                display: block;
            }
        `}
    ${(props) =>
        !props.hasBottomButtonOnMobile &&
        css`
            bottom: -5rem;
            transition: bottom 300ms ease;
        `}
`;
